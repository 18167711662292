.App {
  width: 100vw;
  height: 100vh;
}

.column {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.button-login {
  font-size: 26px;
}
